import {styled} from '@mui/material';
import {memo} from 'react';

const YaKCatchPhraseBox = styled('div')(({theme, sx}) => ({
    padding: '8px 44px',
    [theme.breakpoints.down('md')]: {
        padding: '8px 24px',
    },
    ...sx,
}));

export default memo(YaKCatchPhraseBox);
